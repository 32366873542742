import Alert from "flarum/components/Alert";

export default function(view) {
  if(!app.session.user){
    view.children = [];
    window.location = '/login';
  } else {
    if(!app.session.user.isEmailConfirmed() || app.session.user.groups().length == 0){
      view.children = [];
      const errorAlert = new Alert({
        type: 'error',
        children: 'Seu cadastro ainda não foi confirmado'
      });
      app.alerts.show(errorAlert);
      setInterval(()=>{
        app.session.logout();
      }, 3000);
    }

    if (!window.location.pathname.startsWith("/materiais")) {
      app.session.user.groups().forEach(group => {
        if (group.nameSingular() == "Enfermeiro" || group.nameSingular() == "Agente Comunitário de Saúde") {
          view.children = [];
          window.location = '/materiais';
        }
      });
    }
  }
}
