import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import Button from 'flarum/components/Button';
import EncaminhamentoModal from "../../admin/encaminhamento/components/EncaminhamentoModal";
import DiscussionEncaminhamentoModal from "./components/DiscussionEncaminhamentoModal";
import EncaminhamentoListPage from "../../admin/encaminhamento/components/EncaminhamentoListPage";
import DiscussionPage from "flarum/components/DiscussionPage";
import DiscussionHistoricoEncaminhamentoModal from "./components/DiscussionHistoricoEncaminhamentoModal";

export default function addEncaminhamentoControl() {
    extend(DiscussionControls, 'moderationControls', function(items, discussion) {
        if (discussion.canEncaminhar()) {
            items.add('encaminhar', Button.component({
                children: app.translator.trans(discussion.isEncaminhado() ? 'iete-amigosdacura.forum.discussion_controls.nao_encaminhar_button' : 'iete-amigosdacura.forum.discussion_controls.encaminhar_button'),
                icon: 'fas fa-paper-plane',
                onclick: this.encaminharAction.bind(discussion)
            }));
            items.add('historico', Button.component({
                children: app.translator.trans('iete-amigosdacura.forum.discussion_controls.historico_button'),
                icon: 'fas fa-history',
                onclick: this.historicoAction.bind(discussion)
            }));
        }
    });

    DiscussionControls.encaminharAction = function() {
        if(this.isEncaminhado()){
            this.save({isEncaminhado: false}).then(() => {
                    if (app.current instanceof DiscussionPage) {
                        app.current.stream.update();
                    }
                    m.redraw();
                });
        } else {
            app.store.find('encaminhamentos').then((value) => {
                app.modal.show(new DiscussionEncaminhamentoModal({encaminhamentos: value, discussion: this}));
            });
        }
    };

    DiscussionControls.historicoAction = function() {
        app.modal.show(new DiscussionHistoricoEncaminhamentoModal({discussion: this}));
    };
}
