import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Select from "flarum/components/Select";
import DiscussionPage from 'flarum/components/DiscussionPage';
import Alert from "flarum/components/Alert";
import EncaminhamentoModal from "../../../admin/encaminhamento/components/EncaminhamentoModal";


function HistoricoItem(historico) {
    const encaminhamento = historico.encaminhamento();
    return [
        m('tr', {"data-id": historico.id()}, [
            m('td', [
                encaminhamento.nome()
            ]),
            m('td', [
                moment(historico.created_at()).format("DD/MM/YYYY HH:mm:ss")
            ]),
            m('td', [
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-file-download',
                    onclick: function (e) {
                        e.preventDefault();
                        window.open(app.forum.attribute('apiUrl') + '/historico_encaminhamento/' + historico.id());
                    }
                }),
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-trash',
                    onclick: function (e) {
                        e.preventDefault();
                        if (confirm(app.translator.trans('iete-amigosdacura.forum.modal_historico.delete_confirmation'))) {
                            historico.delete().then(() => {
                                window.location.reload();
                            });
                        }
                    }
                }),
            ])
        ])
    ];
}

/**
 * The `EmailUserModal` component shows a modal dialog which allows admin
 * to send message to user.
 */
export default class DiscussionHistoricoEncaminhamentoModal extends Modal {
    init() {
        super.init();

        this.discussion = this.props.discussion;
        this.historicos = this.discussion.historico();
        this.errorAlert = null;

    }

    className() {
        return 'HistoricoModal';
    }

    title() {
        return app.translator.trans('iete-amigosdacura.forum.modal_historico.title_text');
    }

    content() {
        return <div className="Modal-body">
            <table className="Historico-table">
                <thead>
                <th>
                    {app.translator.trans('iete-amigosdacura.forum.modal_historico.table_destino')}
                </th>
                <th>
                    {app.translator.trans('iete-amigosdacura.forum.modal_historico.table_data')}
                </th>
                <th>
                    {app.translator.trans('iete-amigosdacura.forum.modal_historico.table_acoes')}
                </th>
                </thead>
                <tbody>
                {this.historicos.length > 0 ?
                    this.historicos.map(HistoricoItem) :
                    <td colspan="3"> {app.translator.trans('iete-amigosdacura.forum.modal_historico.table_empty')} </td>}
                </tbody>
            </table>
        </div>
    }

    selectEncaminhamento(val) {
        this.encaminhamento(val);
    }

    onsubmit(e) {
        e.preventDefault();
        if (this.discussion.pacientes().length > 0) {
            if (this.encaminhamento() > -1) {
                app.alerts.dismiss(this.errorAlert);
                this.loading = true;
                this.discussion.save({
                    isEncaminhado: !this.discussion.isEncaminhado(),
                    encaminhamentos_id: this.encaminhamentos[this.encaminhamento()].id(),
                    relationships: {
                        encaminhamento: this.encaminhamentos[this.encaminhamento()]
                    }
                }).then(() => {
                    if (app.current instanceof DiscussionPage) {
                        app.current.stream.update();
                    }
                    this.hide();
                    m.redraw();
                });
            } else {
                this.errorAlert = new Alert({
                    type: 'error',
                    children: app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.title_text')
                });
                app.alerts.show(this.errorAlert);
            }
        } else {
            this.errorAlert = new Alert({
                type: 'error',
                children: app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.paciente_error')
            });
            app.alerts.show(this.errorAlert);
        }

    }
}
