import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class QuizQuestion extends mixin(Model, {
  question: Model.attribute('question'),
  quiz_id: Model.attribute('quiz_id'),
  quiz: Model.hasOne('quiz'),
  options: Model.hasMany('options'),
}) {
}
