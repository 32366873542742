import app from 'flarum/app';
import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

/**
 * The `EmailUserModal` component shows a modal dialog which allows admin
 * to send message to user.
 */
export default class EncaminhamentoModal extends Modal {
    init() {
        super.init();

        this.loading = false;

        this.encaminhamento = this.props.encaminhamento || app.store.createRecord('encaminhamentos');
        this.nome = m.prop(this.encaminhamento.nome() || '');
        this.email = m.prop(this.encaminhamento.email() || '');

        this.submitDisabled = !this.checkEmail(this.email());
    }

    className() {
        return 'EncaminhamentoModal Modal--large';
    }

    title() {
        var title = '';
        if (this.encaminhamento.exists) {
            title += app.translator.trans('iete-amigosdacura.admin.modal.title_edit');
        } else {
            title += app.translator.trans('iete-amigosdacura.admin.modal.title_new');
        }
        title += ' ' + app.translator.trans('iete-amigosdacura.admin.modal_encaminhamento.title_text');
        return title;
    }

    content() {
        return <div className="Modal-body">
            <form className="Form" onsubmit={this.onsubmit.bind(this)}>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.admin.modal_encaminhamento.nome_label')}</label>
                    <input type="text" className="FormControl" bidi={this.nome}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.admin.modal_encaminhamento.email_label')}</label>
                    <input type="text" className="FormControl" value={this.email()}
                           oninput={this.oninputEmail.bind(this)}/>
                </div>

                {Button.component({
                    type: 'submit',
                    className: 'Button Button--primary EditContactModal-save',
                    loading: this.loading,
                    children: app.translator.trans('iete-amigosdacura.admin.modal.submit_button'),
                    disabled: this.submitDisabled
                })}
            </form>
        </div>
    }

    oninputEmail(value) {
        this.email(value.target.value);
        this.submitDisabled = !this.checkEmail(value.target.value);
    }

    checkEmail(email) {
        const emailRegexp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

        let correct = true;
        if (!emailRegexp.test(email)) {
            correct = false;
        }
        return correct;
    }

    onsubmit(e) {
        e.preventDefault();

        this.loading = true;

        let data = {
            nome: this.nome(),
            email: this.email()
        };

        this.encaminhamento.save(data, response => {
            this.loading = false;
            this.onerror(response);
        }).then(() => {
            this.hide();
            m.route(app.route('encaminhamento'));
        }).catch(() => {
            this.loading = false;
            m.redraw();
        });

    }
}
