import Page from 'flarum/components/Page';
import IndexPage from "flarum/components/IndexPage";
import HeroPage from "./HeroPage";

export default class PagePage extends Page {
  init() {
    super.init();

    this.title = 'Título';
    this.subtitle = '';

    /**
     * The page that is being viewed.
     *
     * @type {fof/pages/models/Page}
     */
    this.bodyClass = 'App--page';
  }

  view() {
    if(!app.session.user){
      window.location = '/login';
      return []
    }
    return (
      <div className="Pages">
        <div className="Pages-page">
          {this.hero()}
          <div className="Pages-container container">
            <div className="Post-body">
              {this.content()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  hero() {
    return HeroPage.component({page: this});
  }

  content() {
    return '';
  }

}
