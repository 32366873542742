import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Select from "flarum/components/Select";
import DiscussionPage from 'flarum/components/DiscussionPage';
import Alert from "flarum/components/Alert";

/**
 * The `EmailUserModal` component shows a modal dialog which allows admin
 * to send message to user.
 */
export default class DiscussionEncaminhamentoModal extends Modal {
  init() {
    super.init();

    this.encaminhamentos = this.props.encaminhamentos;
    this.discussion = this.props.discussion;
    this.encaminhamento = m.prop(-1);
    this.errorAlert = null;

  }

  className() {
    return 'EncaminhamentoModal Modal--small';
  }

  title() {
    return app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.title_text');
  }

  content() {
    return <div className="Modal-body">
      <form className="Form" onsubmit={this.onsubmit.bind(this)}>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.encaminhamento_label')}</label>
          {Select.component({
            className: 'FormControl',
            options: this.encaminhamentos.map(x => x.nome() + ' <' + x.email() + '>'),
            onchange: val => this.selectEncaminhamento(val),
            value: this.encaminhamento()
          })}
        </div>

        {Button.component({
          type: 'submit',
          className: 'Button Button--primary SendEncaminhamentoModal-save',
          loading: this.loading,
          children: app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.submit_button')
        })}
      </form>
    </div>
  }

  selectEncaminhamento(val) {
    this.encaminhamento(val);
  }

  onsubmit(e) {
    e.preventDefault();
    if (this.discussion.pacientes().length > 0) {
        if (this.encaminhamento() > -1) {
            app.alerts.dismiss(this.errorAlert);
            this.loading = true;
            this.discussion.save({
                isEncaminhado: !this.discussion.isEncaminhado(),
                encaminhamentos_id: this.encaminhamentos[this.encaminhamento()].id(),
                relationships: {
                    encaminhamento: this.encaminhamentos[this.encaminhamento()]
                }
            }).then(() => {
                if (app.current instanceof DiscussionPage) {
                    app.current.stream.update();
                }
                app.store.find('discussions', this.discussion.id()).then();
                this.hide();
                m.redraw();
            });
        } else {
            this.errorAlert = new Alert({
                type: 'error',
                children: app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.title_text')
            });
            app.alerts.show(this.errorAlert);
        }
    } else {
        this.errorAlert = new Alert({
            type: 'error',
            children: app.translator.trans('iete-amigosdacura.forum.modal_encaminhamento.paciente_error')
        });
        app.alerts.show(this.errorAlert);
    }

  }
}
