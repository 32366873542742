import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import Dropdown from 'flarum/components/Dropdown';
import Button from 'flarum/components/Button';

import PagePage from './PagePage';


function MaterialItem(material) {
    return [
        m('li', {className: "MaterialListItem", "data-id": material.id()}, [
            m('div', {className: 'MaterialListItem-content'}, [
                m('div', {
                    className: 'MaterialListItem-main', onclick: function () {
                        m.route(app.route('material', {id: material.id()}))
                    }
                }, [
                    m('h3', {className: 'MaterialListItem-title'}, [
                        material.title(),
                    ]),
                    m('span', {className: 'MaterialListItem-count'}, [
                        <span className="fas fa-eye"></span>,
                        material.view_count(),
                    ]),
                    m('div', {className: 'MaterialListItem-icon MaterialIcon'}, [
                        m('img', {src: 'https://i3.ytimg.com/vi/' + material.youtube_id() + '/0.jpg'})
                    ]),
                    m('p', {className: 'MaterialListItem-description'}, [
                        material.description(),
                    ])
                ])
            ])
        ])
    ];
}

export default class MateriaisPage extends PagePage {
    init() {
        super.init();

        this.title = "Materiais";
        this.loading = true;
        this.moreResults = false;
        this.materiais = [];
        this.categorias = [];
        this.refresh();
    }

    content() {
        return (
            <div className="MaterialPage">
                <ul className="IndexPage-toolbar-view">{listItems(this.viewItems().toArray())}</ul>
                <ul className="MaterialList">
                    {this.materiais.length > 0 ? this.materiais.map(MaterialItem) : app.translator.trans('iete-amigosdacura.forum.material.empty')}
                </ul>
            </div>
        );
    }

    refresh(clear = true) {
        if (clear) {
            this.loading = true;
            this.materiais = [];
        }

        return this.loadResults().then(
            results => {
                this.materiais = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    loadResults(offset) {
        const params = {};
        params.cat = m.route.param('cat');
        params.page = {
            offset: offset,
            limit: 50
        };
        // params.sort = 'title';
        return app.store.find('materiais', params);
    }

    loadMore() {
        this.loading = true;

        this.loadResults(this.materiais.length)
            .then(this.parseResults.bind(this));
    }

    /**
     *
     * @param {Material[]} results
     * @returns {Material[]}
     */
    parseResults(results) {
        [].push.apply(this.materiais, results);

        this.loading = false;
        this.moreResults = !!results.payload.links.next;

        m.lazyRedraw();

        return results;
    }

    /**
     * Build an item list for the part of the toolbar which is concerned with how
     * the results are displayed. By default this is just a select box to change
     * the way discussions are sorted.
     *
     * @return {ItemList}
     */
    viewItems() {
        const items = new ItemList();

        if(this.categorias.length==0) {
            app.store.find('categorias').then(values => {
                this.categorias = values;
                m.redraw();
            });
        }

        items.add('filter',
            Dropdown.component({
                buttonClassName: 'Button',
                label: this.categoria || "Categoria",
                children: this.categorias.map(value => {
                    const label = value.nome();
                    const active = this.params().cat === value.id();

                    if(active){
                        this.categoria = value.nome();
                    }

                    return Button.component({
                        children: label,
                        icon: active ? 'fas fa-check' : true,
                        onclick: this.changeCat.bind(this, value),
                        active: active,
                    })
                }),
            })
        );

        return items;
    }

    /**
     * Redirect to the index page using the given sort parameter.
     *
     * @param {Categoria} cat
     */
    changeCat(cat) {
        const params = this.params();

        if(cat.id() == params.cat){
            delete params.cat;
        } else {
            params.cat = cat.id();
        }

        m.route(app.route('materiais', params));
    }


    /**
     * Get parameters to pass to the DiscussionList component.
     *
     * @return {Object}
     */
    params() {
        const params = this.stickyParams();

        params.cat = m.route.param('cat');

        return params;
    }

    /**
     * Get URL parameters that stick between filter changes.
     *
     * @return {Object}
     */
    stickyParams() {
        return {
            sort: m.route.param('sort'),
            q: m.route.param('q')
        };
    }
}
