import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class QuestionOption extends mixin(Model, {
  answer: Model.attribute('answer'),
  is_correct: Model.attribute('is_correct'),
  question_id: Model.attribute('question_id'),
  answers: Model.hasMany('answers'),
}) {
}
