import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Select from "flarum/components/Select";
import GroupBadge from "flarum/components/GroupBadge";
import DiscussionPage from 'flarum/components/DiscussionPage';
import Alert from "flarum/components/Alert";
import Group from 'flarum/models/Group';
import Inputmask from 'inputmask';

export default class NewUserModal extends Modal {
  init() {
    super.init();
    this.user = this.props.user || app.store.createRecord('users');
    this.self = this.props.self || false;

    this.cpf = m.prop(this.user.cpf() || '');
    this.full_name = m.prop(this.user.full_name() || '');
    this.name = m.prop(this.user.username() || '');
    this.email = m.prop(this.user.email() || '');
    this.senha = m.prop('');
    this.conselho = m.prop(this.user.conselho() || '');
    this.numero_conselho = m.prop(this.user.conselho_numero() || '');
    this.psf = m.prop(this.user.psf() || '');
    this.cidade = m.prop(this.user.cidade() || '');
    this.estado = m.prop(this.user.estado() || '');
    this.birthday = m.prop(this.user.birthday()!=''?moment(this.user.birthday()).format('DD/MM/YYYY') : '');
    this.groups = {};
    this.isEmailConfirmed = m.prop(this.user.isEmailConfirmed() || false);


    app.store.all('groups')
      .filter(group => [Group.GUEST_ID, Group.MEMBER_ID].indexOf(group.id()) === -1)
      .forEach(group => this.groups[group.id()] = m.prop(this.user.exists && this.user.groups().indexOf(group) !== -1));

    this.errorAlert = null;

  }
  onready() {
    super.onready();
    Inputmask("999.999.999-99").mask(document.querySelector("input[name=cpf]"));
    Inputmask("99/99/9999").mask(document.querySelector("input[name=birthday]"));
  }

  className() {
    return 'NewUserModal';
  }

  title() {
    return app.translator.trans('iete-amigosdacura.forum.modal.user.title_heading');
  }

  content() {
    return <div className="Modal-body">
      <form className="Form" onsubmit={this.onsubmit.bind(this)}>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.full_name')}</label>
          <input type="text" className="FormControl" bidi={this.full_name}/>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.cpf')}</label>
          <input type="text" className="FormControl" name="cpf" bidi={this.cpf}/>
        </div>
        {this.user.exists ? '' : (
          <div className="Form-group">
            <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.name')}</label>
            <input type="text" className="FormControl" bidi={this.name}/>
          </div>
        )}
        {this.user.exists ? '' : (
          <div className="Form-group">
            <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.email')}</label>
            <input type="text" className="FormControl" bidi={this.email}/>
          </div>
        )}

        {!this.isEmailConfirmed() && this.user.exists && app.session.user !== this.user ? (
          <div>
            {Button.component({
              className: 'Button Button--block',
              children: app.translator.trans('core.forum.edit_user.activate_button'),
              loading: this.loading,
              onclick: this.activate.bind(this)
            })}
          </div>
        ) : ''}
        {this.user.exists ? '' : <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.senha')}</label>
          <input type="password" className="FormControl" bidi={this.senha}/>
        </div>}
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.birthday')}</label>
          <input type="text" name="birthday" className="FormControl" bidi={this.birthday}/>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.council')}</label>
          <select className="FormControl" onchange={this.selectConselho.bind(this)}>
            <option selected={this.conselho() == ''} disabled
                    hidden>{app.translator.trans('iete-amigosdacura.forum.modal.user.council_select')}</option>
            <option selected={this.conselho() == 'crm'}
                    value="crm">{app.translator.trans('iete-amigosdacura.forum.council.medicina')}</option>
            <option selected={this.conselho() == 'coren'}
                    value="coren">{app.translator.trans('iete-amigosdacura.forum.council.enfermagem')}</option>
            <option selected={this.conselho() == 'cnes'}
                    value="cnes">{app.translator.trans('iete-amigosdacura.forum.council.cnes')}</option>
          </select>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.council_number')}</label>
          <input type="text" className="FormControl" bidi={this.numero_conselho}/>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.psf')}</label>
          <input type="text" className="FormControl" bidi={this.psf}/>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.cidade')}</label>
          <input type="text" className="FormControl" bidi={this.cidade}/>
        </div>
        <div className="Form-group">
          <label>{app.translator.trans('iete-amigosdacura.forum.modal.user.estado')}</label>
          <select name="estado" className="FormControl" onchange={this.selectEstado.bind(this)}>
            <option selected={this.estado() == 'AC'} value="AC">Acre</option>
            <option selected={this.estado() == 'AL'} value="AL">Alagoas</option>
            <option selected={this.estado() == 'AP'} value="AP">Amapá</option>
            <option selected={this.estado() == 'AM'} value="AM">Amazonas</option>
            <option selected={this.estado() == 'BA'} value="BA">Bahia</option>
            <option selected={this.estado() == 'CE'} value="CE">Ceará</option>
            <option selected={this.estado() == 'DF'} value="DF">Distrito Federal</option>
            <option selected={this.estado() == 'ES'} value="ES">Espírito Santo</option>
            <option selected={this.estado() == 'GO'} value="GO">Goiás</option>
            <option selected={this.estado() == 'MA'} value="MA">Maranhão</option>
            <option selected={this.estado() == 'MT'} value="MT">Mato Grosso</option>
            <option selected={this.estado() == 'MS'} value="MS">Mato Grosso do Sul</option>
            <option selected={this.estado() == 'MG'} value="MG">Minas Gerais</option>
            <option selected={this.estado() == 'PA'} value="PA">Pará</option>
            <option selected={this.estado() == 'PB'} value="PB">Paraíba</option>
            <option selected={this.estado() == 'PR'} value="PR">Paraná</option>
            <option selected={this.estado() == 'PE'} value="PE">Pernambuco</option>
            <option selected={this.estado() == 'PI'} value="PI">Piauí</option>
            <option selected={this.estado() == 'RJ'} value="RJ">Rio de Janeiro</option>
            <option selected={this.estado() == 'RN'} value="RN">Rio Grande do Norte</option>
            <option selected={this.estado() == 'RS'} value="RS">Rio Grande do Sul</option>
            <option selected={this.estado() == 'RO'} value="RO">Rondônia</option>
            <option selected={this.estado() == 'RR'} value="RR">Roraima</option>
            <option selected={this.estado() == 'SC'} value="SC">Santa Catarina</option>
            <option selected={this.estado() == 'SP'} value="SP">São Paulo</option>
            <option selected={this.estado() == 'SE'} value="SE">Sergipe</option>
            <option selected={this.estado() == 'TO'} value="TO">Tocantins</option>
          </select>
        </div>
        {this.self?'' : (
        <div className="Form-group EditUserModal-groups">
          <label>{app.translator.trans('core.forum.edit_user.groups_heading')}</label>
          <div>
            {Object.keys(this.groups)
              .map(id => app.store.getById('groups', id))
              .map(group => (
                <label className="checkbox">
                  <input type="checkbox"
                         bidi={this.groups[group.id()]}
                         disabled={group.id() === Group.ADMINISTRATOR_ID}/>
                  {GroupBadge.component({group, label: ''})} {group.nameSingular()}
                </label>
              ))}
          </div>
        </div>
        )}
        {Button.component({
          type: 'submit',
          className: 'Button Button--primary SendNewUserModal-save',
          loading: this.loading,
          children: app.translator.trans('iete-amigosdacura.forum.save')
        })}
      </form>
    </div>
  }

  selectConselho(val) {
    this.conselho(val.target.value);
  }

  selectEstado(val) {
    this.estado(val.target.value);
  }

  validate(data){
    if(!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/.test(data.birthday)){
      const errorAlert = new Alert({
        type: 'error',
        children: 'A data de nascimento deve ser no formato DD/MM/AAAA'
      });
      app.alerts.show(errorAlert);
      return false;
    }

    const nascimento = moment(data.birthday, 'DD/MM/YYYY');
    if(!nascimento.isValid() || moment().diff(nascimento)<0){

      const errorAlert = new Alert({
        type: 'error',
        children: 'Data de nascimento inválida'
      });
      app.alerts.show(errorAlert);
      return false;
    }
    return true;
  }

  onsubmit(e) {
    e.preventDefault();
    this.loading = true;


    var data = {
      full_name: this.full_name(),
      cpf: this.cpf(),
      conselho: this.conselho(),
      conselho_numero: this.numero_conselho(),
      psf: this.psf(),
      cidade: this.cidade(),
      estado: this.estado(),
      birthday: this.birthday(),
    };
    if (!this.user.exists) {
      data.password = this.senha();
      data.username = this.name();
      data.email = this.email();
    }

    if(!this.self){
      const groups = Object.keys(this.groups)
        .filter(id => this.groups[id]())
        .map(id => app.store.getById('groups', id));
      data.relationships = {groups: groups}
    }

    if(app.session.user)

    if (!this.validate(data)) {
      this.loading = false;
      return;
    }

    data.birthday = moment(data.birthday, 'DD/MM/YYYY');

    this.user.save(data, {errorHandler: this.onerror.bind(this)}).then(() => {
      this.hide();
      m.redraw();
    });

  }

  activate() {
    this.loading = true;
    const data = {
      username: this.name(),
      isEmailConfirmed: true,
    };
    this.user.save(data, {errorHandler: this.onerror.bind(this)})
      .then(() => {
        this.isEmailConfirmed(true);
        this.loading = false;
        m.redraw();
      })
      .catch(() => {
        this.loading = false;
        m.redraw();
      });
  }
}
