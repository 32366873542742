import {extend, override} from 'flarum/extend';
import app from 'flarum/app';
import Model from 'flarum/Model';
import Discussion from 'flarum/models/Discussion';
import User from 'flarum/models/User';
import NotificationGrid from 'flarum/components/NotificationGrid';

import DiscussionEncaminhadoPost from './encaminhamento/components/DiscussionEncaminhadoPost'
import DiscussionEncaminhadoNotification from './encaminhamento/components/DiscussionEncaminhadoNotification'
import addEncaminhamentoBadge from './encaminhamento/addEncaminhamentoBadge';
import addEncaminhamentoControl from './encaminhamento/addEncaminhamentoControl';
import Encaminhamento from "../common/model/Encaminhamento";
import loginRedirect from "./login/loginRedirect";
import IndexPage from "flarum/components/IndexPage";
import addHomeRoute from "./home/addHomeRoute";
import HomePage from "./home/components/HomePage";
import MateriaisPage from "./home/components/MateriaisPage";
import Material from "../common/model/Material";
import Paciente from "../common/model/Paciente";
import addComposerFields from "./paciente/addComposerFields";
import addFieldsOnDiscussionPost from "./paciente/addFieldsOnDiscussionPost";
import addNewUser from "./user/addNewUser";
import addMateriais from "./materiais/addMateriais";
import LogInModal from "flarum/components/LogInModal";
import HistoricoEncaminhamento from "../common/model/HistoricoEncaminhamento";
import Categoria from "../common/model/Categoria";
import addPacienteCrud from "./paciente/addPacienteCrud";
import UserDirectoryPage from "./user/components/UserDirectoryPage";
import Quiz from "../common/model/Quiz";
import QuizQuestion from "../common/model/QuizQuestion";
import QuestionOption from "../common/model/QuestionOption";
import QuizAnswer from "../common/model/QuizAnswer";
import AmigosSignUpModal from "./login/AmigosSignUpModal";

app.initializers.add('iete-amigosdacura', () => {

  app.routes.flagrow_user_directory = {path: '/users', component: UserDirectoryPage.component()};

  const loginRequired = [
    IndexPage.prototype,
    HomePage.prototype,
    MateriaisPage.prototype,
    UserDirectoryPage.prototype
  ];
  loginRequired.forEach((page) => {
    extend(page, 'view', function (view) {
      loginRedirect(view);
    });
  });

  addHomeRoute();

  app.store.models.encaminhamentos = Encaminhamento;
  app.store.models.materiais = Material;
  app.store.models.categorias = Categoria;
  app.store.models.pacientes = Paciente;
  app.store.models.historico_encaminhamento = HistoricoEncaminhamento;
  app.postComponents.discussionEncaminhado = DiscussionEncaminhadoPost;
  app.notificationComponents.discussionEncaminhado = DiscussionEncaminhadoNotification;
  app.store.models.quiz = Quiz;
  app.store.models.quiz_question = QuizQuestion;
  app.store.models.question_option = QuestionOption;
  app.store.models.quiz_answer = QuizAnswer;

  Discussion.prototype.isEncaminhado = Model.attribute('isEncaminhado');
  Discussion.prototype.canEncaminhar = Model.attribute('canEncaminhar');
  Discussion.prototype.encaminhamento = Model.hasOne('encaminhamento');

  Discussion.prototype.pacientes = Model.hasMany('pacientes');
  Discussion.prototype.historico = Model.hasMany('historico');
  Discussion.prototype.canUpdatePaciente = Model.attribute('canUpdatePaciente');

  User.prototype.conselho = Model.attribute('conselho');
  User.prototype.conselho_numero = Model.attribute('conselho_numero');
  User.prototype.psf = Model.attribute('psf');
  User.prototype.cidade = Model.attribute('cidade');
  User.prototype.estado = Model.attribute('estado');
  User.prototype.birthday = Model.attribute('birthday');
  User.prototype.cpf = Model.attribute('cpf');
  User.prototype.full_name = Model.attribute('full_name');

  addEncaminhamentoBadge();
  addEncaminhamentoControl();
  addComposerFields();
  addFieldsOnDiscussionPost();
  addNewUser();
  addMateriais();
  addPacienteCrud();

  extend(NotificationGrid.prototype, 'notificationTypes', function (items) {
    items.add('discussionEncaminhado', {
      name: 'discussionEncaminhado',
      icon: 'fas fa-paper-plane',
      label: app.translator.trans('iete-amigosdacura.forum.settings.notify_discussion_encaminhado_label')
    });
  });

  override(LogInModal.prototype, 'title', function () {
    return app.forum.attribute('title');
  });

  extend(LogInModal.prototype, 'body', function (views) {
    views.splice(0, 0, <p class="Text--centered">{app.translator.trans("iete-amigosdacura.forum.login_welcome")}</p>);
  });

  override(LogInModal.prototype, 'signUp', function () {
    console.log('ok');
    const props = {password: this.password()};
    const identification = this.identification();
    props[identification.indexOf('@') !== -1 ? 'email' : 'username'] = identification;

    app.modal.show(new AmigosSignUpModal(props));
  })
});
