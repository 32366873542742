import Component from "flarum/Component";

export default class HeroPage extends Component {
    view(){
        const page = this.props.page;
        return (
          <header className="Hero PageHero">
              <div className="container">
                  <div className="containerNarrow">
                      <h2 className="Hero-title">{page.title}</h2>
                      <div className="Hero-subtitle">{page.subtitle}</div>
                  </div>
              </div>
          </header>
        );
    }
}
