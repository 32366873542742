import {extend} from 'flarum/extend';
import app from 'flarum/app';
import UsersSearchSource from 'flarum/components/UsersSearchSource';
import LinkButton from 'flarum/components/LinkButton';
import UserDirectoryPage from './components/UserDirectoryPage';
import UserControls from "flarum/utils/UserControls";
import NewUserModal from "./components/NewUserModal";
import Button from 'flarum/components/Button';
import IndexPage from "flarum/components/IndexPage";
import DiscussionList from "flarum/components/DiscussionList";

export default function () {

    extend(IndexPage.prototype, 'navItems', function (items) {
        const params = this.stickyParams();
        params.filter = "encaminhados";
        items.add('encaminhados',
            LinkButton.component({
                href: app.route('index.filter', params),
                children: app.translator.trans('iete-amigosdacura.forum.nav.encaminhados'),
                icon: 'far fa-paper-plane'
            }),
            82
        );
        items.add('userDirectory',
            LinkButton.component({
                href: app.route('flagrow_user_directory', this.stickyParams()),
                children: app.translator.trans('iete-amigosdacura.forum.nav.users'),
                icon: 'far fa-address-book'
            }),
            85
        );
    });

    extend(DiscussionList.prototype, 'requestParams', function(params) {
        if (this.props.params.filter === 'encaminhados') {
            params.filter.q = (params.filter.q || '') + ' is:encaminhado';
        }
    });

    extend(UsersSearchSource.prototype, 'view', function (view, query) {
        if (!view) {
            return;
        }

        query = query.toLowerCase();

        view.splice(1, 0, <li>
            {LinkButton.component({
                icon: 'fas fa-search',
                children: app.translator.trans('flagrow-user-directory.forum.search.users_heading', {query}),
                href: app.route('flagrow_user_directory', {q: query}),
            })}
        </li>);
    });

    extend(UserControls, 'moderationControls', function (items, user) {
        if(items.has('edit')) {
            items.replace('edit', Button.component({
                icon: 'fas fa-pencil-alt',
                children: app.translator.trans('core.forum.user_controls.edit_button'),
                onclick: ()=>{
                    app.modal.show(new NewUserModal({user: user}));
                }
            }));
        } else if(app.session.user.id() == user.id()){
          items.add('edit', Button.component({
            icon: 'fas fa-pencil-alt',
            children: app.translator.trans('core.forum.user_controls.edit_button'),
            onclick: ()=>{
              app.modal.show(new NewUserModal({user: user, self: true}));
            }
          }));
        }
    });

}
