import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';
import { getPlainContent } from 'flarum/utils/string';

export default class HistoricoEncaminhamento extends mixin(Model, {
    discussion: Model.hasOne('discussion'),
    encaminhamento: Model.hasOne('encaminhamento'),
    user: Model.hasOne('user'),
    paciente: Model.hasOne('paciente'),
    discussion_id: Model.attribute('discussion_id'),
    encaminhamento_id: Model.attribute('encaminhamento_id'),
    user_id: Model.attribute('user_id'),
    paciente_id: Model.attribute('paciente_id'),
    created_at: Model.attribute('created_at'),
}) {}
