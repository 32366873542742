import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class QuizAnswer extends mixin(Model, {
  questions: Model.attribute('questions'),
  answer_id: Model.attribute('answer_id'),
  user_id: Model.attribute('user_id'),
  question_id: Model.attribute('question_id'),
  user: Model.hasOne('user'),
}) {
}
