/**
 * The `MaterialSearchSource` finds and displays material search results in the search
 * dropdown.
 *
 * @implements SearchSource
 */
export default class MaterialSearchSource {
  constructor() {
    this.results = {};
  }

  search(query) {
    return app.store.find('materiais', {
      filter: {q: query},
      page: {limit: 5}
    }).then(results => {
      this.results[query] = results;
      m.redraw();
    });
  }

  view(query) {
    query = query.toLowerCase();

    const results = (this.results[query] || [])
      .concat(app.store.all('materiais').filter(material => [material.title(), material.description()].some(value => value.toLowerCase().substr(0, query.length) === query)))
      .filter((e, i, arr) => arr.lastIndexOf(e) === i)
      .sort((a, b) => a.title().localeCompare(b.title()));

    if (!results.length) return '';

    return [
      <li className="Dropdown-header">{app.translator.trans('iete-amigosdacura.forum.search.materiais_heading')}</li>,
      results.map(material => {
        const name = material.title();

        return (
          <li className="MateriaisSearchResult" data-index={'materiais' + material.id()}>
            <a href={app.route.materiais} config={m.route}>
              {name}
            </a>
          </li>
        );
      })
    ];
  }
}
