import Modal from 'flarum/components/Modal';
import LogInModal from 'flarum/components/LogInModal';
import Button from 'flarum/components/Button';
import LogInButtons from 'flarum/components/LogInButtons';
import extractText from 'flarum/utils/extractText';
import ItemList from 'flarum/utils/ItemList';
import Alert from "flarum/components/Alert";
import Inputmask from 'inputmask';

/**
 * The `SignUpModal` component displays a modal dialog with a singup form.
 *
 * ### Props
 *
 * - `username`
 * - `email`
 * - `password`
 * - `token` An email token to sign up with.
 */
export default class AmigosSignUpModal extends Modal {

  init() {
    super.init();

    this.user = this.props.user || app.store.createRecord('users');

    this.cpf = m.prop(this.user.cpf() || '');
    this.full_name = m.prop(this.user.full_name() || '');

    this.name = m.prop(this.user.username() || '');
    this.email = m.prop(this.user.email() || '');
    this.senha = m.prop('');
    this.conselho = m.prop(this.user.conselho() || '');
    this.numero_conselho = m.prop(this.user.conselho_numero() || '');
    this.psf = m.prop(this.user.psf() || '');
    this.birthday = m.prop(this.user.birthday() || '');
    this.cidade = m.prop('');
    this.estado = m.prop('');
    this.groups = {};
    this.isEmailConfirmed = m.prop(this.user.isEmailConfirmed() || false);
    $(document).ready(function(){
      Inputmask("99/99/9999").mask(document.querySelector("input[name=birthday]"));
      Inputmask("999.999.999-99").mask(document.querySelector("input[name=cpf]"));
    });
  }

  className() {
    return 'Modal--small SignUpModal';
  }

  title() {
    return app.translator.trans('core.forum.sign_up.title');
  }

  content() {
    return [
      <div className="Modal-body">
        {this.body()}
      </div>,
      <div className="Modal-footer">
        {this.footer()}
      </div>
    ];
  }

  body() {
    return [
      this.props.token ? '' : <LogInButtons/>,

      <div className="Form Form--centered">
        {this.fields().toArray()}
      </div>
    ];
  }

  fields() {
    const items = new ItemList();
    items.add('full_name', <div className="Form-group">
      <input className="FormControl" name="full_name" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.full_name'))}
             value={this.full_name()}
             onchange={m.withAttr('value', this.full_name)}
             disabled={this.loading}
             required/>
    </div>, 100);

    items.add('cpf', <div className="Form-group">
      <input className="FormControl" name="cpf" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.cpf'))}
             value={this.cpf()}
             onchange={m.withAttr('value', this.cpf)}
             disabled={this.loading}
             required/>
    </div>, 100);

    items.add('username', <div className="Form-group">
      <input className="FormControl" name="username" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.name'))}
             value={this.name()}
             onchange={m.withAttr('value', this.name)}
             disabled={this.loading}
             required/>
    </div>, 100);
    items.add('email', <div className="Form-group">
      <input className="FormControl" name="email" type="email"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.email'))}
             value={this.email()}
             onchange={m.withAttr('value', this.email)}
             disabled={this.loading}
             required/>
    </div>, 90);
    items.add('password', <div className="Form-group">
      <input className="FormControl" name="password" type="password"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.senha'))}
             value={this.senha()}
             onchange={m.withAttr('value', this.senha)}
             disabled={this.loading}
             required/>
    </div>, 80);
    items.add('council', <div className="Form-group">
      <select className="FormControl" onchange={this.selectConselho.bind(this)}
              required>
        <option selected={this.conselho() == ''} disabled
                hidden>{app.translator.trans('iete-amigosdacura.forum.modal.user.council_select')}</option>
        <option selected={this.conselho() == 'crm'}
                value="crm">{app.translator.trans('iete-amigosdacura.forum.council.medicina')}</option>
        <option selected={this.conselho() == 'coren'}
                value="coren">{app.translator.trans('iete-amigosdacura.forum.council.enfermagem')}</option>
        <option selected={this.conselho() == 'cnes'}
                value="cnes">{app.translator.trans('iete-amigosdacura.forum.council.cnes')}</option>
      </select>
    </div>, 70);
    items.add('council_number', <div className="Form-group">
      <input className="FormControl" name="council_number" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.council_number'))}
             value={this.numero_conselho()}
             onchange={m.withAttr('value', this.numero_conselho)}
             disabled={this.loading}
             required/>
    </div>, 60);
    items.add('psf', <div className="Form-group">
      <input className="FormControl" name="psf" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.psf'))}
             value={this.psf()}
             onchange={m.withAttr('value', this.psf)}
             disabled={this.loading}
             required/>
    </div>, 50);
    items.add('birthday', <div className="Form-group">
      <input className="FormControl" name="birthday" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.birthday'))}
             value={this.birthday()}
             onchange={m.withAttr('value', this.birthday)}
             disabled={this.loading}
             required/>
    </div>, 40);
    items.add('cidade', <div className="Form-group">
      <input className="FormControl" name="cidade" type="text"
             placeholder={extractText(app.translator.trans('iete-amigosdacura.forum.modal.user.cidade'))}
             value={this.cidade()}
             onchange={m.withAttr('value', this.cidade)}
             disabled={this.loading}
             required/>
    </div>, 30);
    items.add('estado',<div className="Form-group">
    <select name="estado" className="FormControl" onchange={this.selectEstado.bind(this)}>
      <option selected={this.estado() == 'AC'} value="AC">Acre</option>
      <option selected={this.estado() == 'AL'} value="AL">Alagoas</option>
      <option selected={this.estado() == 'AP'} value="AP">Amapá</option>
      <option selected={this.estado() == 'AM'} value="AM">Amazonas</option>
      <option selected={this.estado() == 'BA'} value="BA">Bahia</option>
      <option selected={this.estado() == 'CE'} value="CE">Ceará</option>
      <option selected={this.estado() == 'DF'} value="DF">Distrito Federal</option>
      <option selected={this.estado() == 'ES'} value="ES">Espírito Santo</option>
      <option selected={this.estado() == 'GO'} value="GO">Goiás</option>
      <option selected={this.estado() == 'MA'} value="MA">Maranhão</option>
      <option selected={this.estado() == 'MT'} value="MT">Mato Grosso</option>
      <option selected={this.estado() == 'MS'} value="MS">Mato Grosso do Sul</option>
      <option selected={this.estado() == 'MG'} value="MG">Minas Gerais</option>
      <option selected={this.estado() == 'PA'} value="PA">Pará</option>
      <option selected={this.estado() == 'PB'} value="PB">Paraíba</option>
      <option selected={this.estado() == 'PR'} value="PR">Paraná</option>
      <option selected={this.estado() == 'PE'} value="PE">Pernambuco</option>
      <option selected={this.estado() == 'PI'} value="PI">Piauí</option>
      <option selected={this.estado() == 'RJ'} value="RJ">Rio de Janeiro</option>
      <option selected={this.estado() == 'RN'} value="RN">Rio Grande do Norte</option>
      <option selected={this.estado() == 'RS'} value="RS">Rio Grande do Sul</option>
      <option selected={this.estado() == 'RO'} value="RO">Rondônia</option>
      <option selected={this.estado() == 'RR'} value="RR">Roraima</option>
      <option selected={this.estado() == 'SC'} value="SC">Santa Catarina</option>
      <option selected={this.estado() == 'SP'} value="SP">São Paulo</option>
      <option selected={this.estado() == 'SE'} value="SE">Sergipe</option>
      <option selected={this.estado() == 'TO'} value="TO">Tocantins</option>
    </select>
  </div>,30);

    items.add('submit', <div className="Form-group">
      <Button
        className="Button Button--primary Button--block"
        type="submit"
        loading={this.loading}>
        {app.translator.trans('core.forum.sign_up.submit_button')}
      </Button>
    </div>, -10);

    return items;
  }

  footer() {
    return [
      <p className="SignUpModal-logIn">
        {app.translator.trans('core.forum.sign_up.log_in_text', {a: <a onclick={this.logIn.bind(this)}/>})}
      </p>
    ];
  }

  /**
   * Open the log in modal, prefilling it with an email/username/password if
   * the user has entered one.
   *
   * @public
   */
  logIn() {
    const props = {
      identification: this.email() || this.username(),
      password: this.password()
    };

    app.modal.show(new LogInModal(props));
  }

  selectConselho(val) {
    this.conselho(val.target.value);
  }

  selectEstado(val) {
    this.estado(val.target.value);
  }

  onsubmit(e) {
    e.preventDefault();
    this.loading = true;

    //const groups = Object.keys(this.groups)
    //    .filter(id => this.groups[id]())
    //    .map(id => app.store.getById('groups', id));

    var data = {
      full_name: this.full_name(),
      cpf: this.cpf(),
      username: this.name(),
      email: this.email(),
      conselho: this.conselho(),
      conselho_numero: this.numero_conselho(),
      psf: this.psf(),
      birthday: this.birthday(),
      cidade: this.cidade(),
      estado: this.estado(),
      //relationships: {
      //    groups
      //}
    };

    if (!this.user.exists) {
      data.password = this.senha();
    }

    if (!this.validate(data)) {
      this.loading = false;
      return;
    }

    data.birthday = moment(data.birthday, 'DD/MM/YYYY');

    this.user.save(data, {errorHandler: this.onerror.bind(this)}).then(() => {
      this.hide();
      m.redraw();
    });
  }

  validateCPF(cpf){

    let i;
    let add;
    let rev;

    cpf = cpf.replace(/[^\d]+/g,'');
    if(cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    // Valida 1o digito
    add = 0;
    for (i=0; i < 9; i ++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i ++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  }

  validate(data) {

    if(!(data.full_name
      && data.cpf
      && data.username
      && data.email
      && data.conselho
      && data.conselho_numero
      && data.psf
      && data.password
      && data.birthday
      && data.cidade
      && data.estado)){
      const errorAlert = new Alert({
        type: 'error',
        children: 'Algum campo em branco'
      });
      app.alerts.show(errorAlert);
      return false;
    }

    if(!/^(\d{3}\.){2}\d{3}\-\d{2}$/.test(data.cpf)){
      const errorAlert = new Alert({
        type: 'error',
        children: 'O CPF deve ser no formato 000.000.000-00'
      });
      app.alerts.show(errorAlert);
      return false;
    }

    if(!this.validateCPF(data.cpf)){
      const errorAlert = new Alert({
        type: 'error',
        children: 'CPF inválido'
      });
      app.alerts.show(errorAlert);
      return false;
    }

    if(!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/.test(data.birthday)){
      const errorAlert = new Alert({
        type: 'error',
        children: 'A data de nascimento deve ser no formato DD/MM/AAAA'
      });
      app.alerts.show(errorAlert);
      return false;
    }

    const nascimento = moment(data.birthday, 'DD/MM/YYYY');
    if(!nascimento.isValid() || moment().diff(nascimento)<0){

      const errorAlert = new Alert({
        type: 'error',
        children: 'Data de nascimento inválida'
      });
      app.alerts.show(errorAlert);
      return false;
    }
    return true;
  }

  onerror(error) {
    this.loading = false;
    super.onerror(error);
  }
}
