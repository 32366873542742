import app from 'flarum/app';
import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import DiscussionPacienteField from "./DiscussionPacienteField";

export default class DiscussionPacienteFieldModal extends Modal {
    init() {
        super.init();

        this.paciente = this.props.discussion.pacientes()[0];
        this.dirty = false;
        this.processing = false;

        // Stays null if the feature is not used
        this.tags = null;
    }

    title() {
        return app.translator.trans('iete-amigosdacura.forum.paciente.modal.edit', {
            title: m('em', this.props.discussion.title()),
        });
    }

    content() {
        return [
            m('.Modal-body', DiscussionPacienteField.component({
                discussion: this.props.discussion, // Only for the tags feature
                paciente: this.paciente,
                onchange: this.pacienteChanged.bind(this)
            })),
            m('.Modal-footer', [
                Button.component({
                    className: 'Button Button--primary',
                    children: app.translator.trans('iete-amigosdacura.forum.save'),
                    loading: this.processing,
                    disabled: !this.dirty,
                    onclick: this.saveAnswers.bind(this),
                }),
            ]),
        ];
    }

    pacienteChanged(paciente) {
        this.paciente = paciente;
        this.dirty = true;
    }

    saveAnswers() {
        this.processing = true;

        let relationships = {
            pacientes: this.paciente,
        };

        this.props.discussion.save({
            relationships,
        }).then(() => {
            this.processing = false;
            app.modal.close();
            m.redraw();
        }).catch(err => {
            this.processing = false;
            throw err;
        });
    }
}
