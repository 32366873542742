import IndexPage from 'flarum/components/IndexPage';
import icon from 'flarum/helpers/icon';

import PagePage from './PagePage';

export default class HomePage extends PagePage {
    init() {
        super.init();
        this.hidden = localStorage.getItem('welcomeHidden');

        this.title = "Home"
    }

    hero(){
        return '';
    }

    content() {
        return(
          <div>
              Content
          </div>
        );
    }
}
