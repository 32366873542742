import app from 'flarum/app';
import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

/**
 * The `EmailUserModal` component shows a modal dialog which allows admin
 * to send message to user.
 */
export default class PacientesModal extends Modal {
    init() {
        super.init();

        this.loading = false;

        this.paciente = this.props.paciente || app.store.createRecord('pacientes');
        this.nome = m.prop(this.paciente.nome() || '');
        this.nascimento = m.prop(this.paciente.nascimento() || '');
        this.sus = m.prop(this.paciente.sus() || '');
        this.tel = m.prop(this.paciente.tel() || '');
        this.mae = m.prop(this.paciente.mae() || '');
        this.endereco = m.prop(this.paciente.endereco() || '');
        this.suspeita = m.prop(this.paciente.suspeita() || '');
        this.status = m.prop(this.paciente.status() || '');
        this.inicio_sintomas = m.prop(this.paciente.inicio_sintomas() || '');
    }

    className() {
        return 'PacienteModal Modal--large';
    }

    title() {
        var title = '';
        if (this.paciente.exists) {
            title += app.translator.trans('iete-amigosdacura.forum.modal.title_edit');
        } else {
            title += app.translator.trans('iete-amigosdacura.forum.modal.title_new');
        }
        title += ' ' + app.translator.trans('iete-amigosdacura.forum.modal_paciente.title_text');
        return title;
    }

    content() {
        return <div className="Modal-body">
            <form className="Form" onsubmit={this.onsubmit.bind(this)}>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.nome_label')}</label>
                    <input type="text" className="FormControl" bidi={this.nome}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.nascimento_label')}</label>
                    <input type="date" className="FormControl" bidi={this.nascimento}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.sus_label')}</label>
                    <input type="text" className="FormControl" bidi={this.sus}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.tel_label')}</label>
                    <input type="text" className="FormControl" bidi={this.tel}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.mae_label')}</label>
                    <input type="text" className="FormControl" bidi={this.mae}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.endereco_label')}</label>
                    <input type="text" className="FormControl" bidi={this.endereco}/>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.suspeita_label')}</label>
                    <input type="text" className="FormControl" bidi={this.suspeita}/>
                </div>
                <div className="Form-group">
                  <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.status_label')}</label>
                  <select className="FormControl" onchange={this.selectStatus.bind(this)}>
                  <option selected={this.status() == 'A confirmar'} value="A confirmar">A confirmar</option>
                    <option selected={this.status() == 'Imprimir cartão'} value="Imprimir cartão">Imprimir cartão</option>
                    <option selected={this.status() == 'Cartão Entregue'} value="Cartão Entregue">Cartão Entregue</option>
                    <option selected={this.status() == 'Iniciou tratamento'} value="Iniciou tratamento">Iniciou tratamento</option>
                    <option selected={this.status() == 'Câncer descartado'} value="Câncer descartado">Câncer descartado</option>
                    <option selected={this.status() == 'Curado'} value="Curado">Curado</option>
                    <option selected={this.status() == 'Óbito'} value="Óbito">Óbito</option>
                  </select>
                </div>
                <div className="Form-group">
                    <label>{app.translator.trans('iete-amigosdacura.forum.modal_paciente.inicio_sintomas_label')}</label>
                    <input type="date" className="FormControl" bidi={this.inicio_sintomas}/>
                </div>

                {Button.component({
                    type: 'submit',
                    className: 'Button Button--primary EditContactModal-save',
                    loading: this.loading,
                    children: app.translator.trans('iete-amigosdacura.forum.modal.submit_button'),
                    disabled: this.submitDisabled
                })}
            </form>
        </div>
    }

    selectPublico(value) {
        this.publico(value.target.value);
    }

    selectStatus(value) {
        this.status(value.target.value);
    }

    selectFile(value) {
        this.arquivo(value.target.files[0]);
    }

    oninputEmail(value) {
        this.email(value.target.value);
        this.submitDisabled = !this.checkEmail(value.target.value);
    }

    onsubmit(e) {

        e.preventDefault();

        this.loading = true;

        let data = {
            nome: this.nome(),
            nascimento: this.nascimento(),
            sus: this.sus(),
            tel: this.tel(),
            mae: this.mae(),
            endereco: this.endereco(),
            suspeita: this.suspeita(),
            status: this.status(),
            inicio_sintomas: this.inicio_sintomas(),
        };

        this.paciente.save(data, response => {
            this.loading = false;
            this.onerror(response);
        }).then(() => {
            this.hide();
            m.route(app.route('paciente'));
        }).catch(() => {
            this.loading = false;
            m.redraw();
        });
    }

}
