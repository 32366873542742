import { extend, override } from 'flarum/extend';
import IndexPage from "flarum/components/IndexPage";
import HomePage from "./components/HomePage";
import MateriaisPage from "./components/MateriaisPage";
import HeroPage from "./components/HeroPage";
import ItemList from "flarum/utils/ItemList";
import LinkButton from 'flarum/components/LinkButton'

export default function addHomeRoute() {

    // app.routes.index = {
    //     path: '/',
    //     component: HomePage.component(),
    // };

    // app.routes.diagnosticos = {
    //     path: '/diagnosticos',
    //     component: IndexPage.component(),
    // };


    // override(IndexPage.prototype, 'navItems', function () {
    //     const items = new ItemList();
    //     const params = this.stickyParams();
    //
    //     items.add('allDiscussions',
    //       LinkButton.component({
    //           href: app.route('diagnosticos', params),
    //           children: app.translator.trans('core.forum.index.all_discussions_link'),
    //           icon: 'far fa-comments'
    //       }),
    //       100
    //     );
    //
    //     return items;
    // });

    override(IndexPage.prototype, 'hero', function () {
        return HeroPage.component({page: {title: "Diagnósticos", subtitle: ""}});
    });
}
