import Notification from 'flarum/components/Notification';

export default class DiscussionEncaminhadoNotification extends Notification {
  icon() {
    return 'fas fa-paper-plane';
  }

  href() {
    const notification = this.props.notification;

    return app.route.discussion(notification.subject(), notification.content().postNumber);
  }

  content() {
    return app.translator.trans('iete-amigosdacura.forum.notifications.discussion_encaminhado_text', {user: this.props.notification.fromUser()});
  }
}
