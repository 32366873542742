import app from 'flarum/app';
import icon from 'flarum/helpers/icon';
import ItemList from 'flarum/utils/ItemList';
import Component from 'flarum/Component';

export default class DiscussionPacienteField extends Component {
    init() {
        this.pacientes = app.store.all('pacientes');
        this.paciente = this.props.paciente;
        this.onchange = this.props.onchange;
    }

    view() {
        return m('form.Discussion-Paciente-Fields.Discussion-Paciente-Fields--editor', {
            onsubmit(event) {
                event.preventDefault();
            },
        }, [
            m('.Discussion-Paciente-Wrapper',
                m('.Discussion-Paciente-Grid', [
                    this.item()
                ])
            )
        ]);
    }

    item() {
        return m('.Discussion-Paciente-Row', [
            m('.Discussion-Paciente-Column', [
                m('.Discussion-Paciente-Field.Form-group', [
                    m('label', app.translator.trans('iete-amigosdacura.forum.paciente.paciente_label')),
                    m('span.Select', [
                        m('select.Select-input.FormControl', {
                            onchange: event => {
                                this.paciente = app.store.getById('pacientes', event.target.value);
                                this.onchange(this.paciente);
                            },
                            required: true,
                        }, [
                            m('option', {
                                value: 'none',
                                selected: !this.paciente,
                                disabled: true,
                                hidden: true,
                            }, app.translator.trans('iete-amigosdacura.forum.paciente.modal.placeholder')),
                            this.pacientes.map(
                                paciente =>
                                    m('option', {
                                        value: paciente.id(),
                                        selected: (this.paciente && this.paciente.id() == paciente.id())
                                    }, paciente.nome())
                            ),
                        ]),
                        icon('fas fa-caret-down', {className: 'Select-caret'}),
                    ])
                ])
            ])
        ])
    }

}
