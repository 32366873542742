import { extend } from 'flarum/extend';
import Discussion from 'flarum/models/Discussion';
import Badge from 'flarum/components/Badge';

export default function addEncaminhamentoBadge() {
  extend(Discussion.prototype, 'badges', function(badges) {
    if (this.isEncaminhado()) {
      badges.add('encaminhado', Badge.component({
        type: 'encaminhado',
        label: app.translator.trans('iete-amigosdacura.forum.badge.encaminhado_tooltip'),
        icon: 'fas fa-paper-plane'
      }));
    }
  });
}
