import EventPost from 'flarum/components/EventPost';

export default class DiscussionEncaminhadoPost extends EventPost {
  icon() {
    return this.props.post.content().encaminhado
      ? 'fas fa-paper-plane'
      : 'fas fa-ban';
  }

  descriptionKey() {
    return this.props.post.content().encaminhado
      ? 'iete-amigosdacura.forum.post_stream.discussion_encaminhado_text'
      : 'iete-amigosdacura.forum.post_stream.discussion_nao_encaminhado_text';
  }
}
