import app from 'flarum/app';
import Page from 'flarum/components/Page';
import Button from 'flarum/components/Button';
import LoadingIndicator from 'flarum/components/LoadingIndicator';

import EncaminhamentoModal from './EncaminhamentoModal';

function EncaminhamentoItem(encaminhamento) {
    return [
        m('li', {"data-id": encaminhamento.id()}, [
            m('div', {className: 'Item-info'}, [
                m('span', {className: 'Item-name'}, [
                    encaminhamento.nome(),
                ]),
                m('span', {className: 'Item-email'}, [
                    encaminhamento.email()
                ]),
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-trash',
                    onclick: function (e) {
                        e.preventDefault();
                        if (confirm(app.translator.trans('iete-amigosdacura.admin.modal_encaminhamento.delete_confirmation'))) {
                            encaminhamento.delete().then(() => m.route(app.route('encaminhamento')));
                        }
                    }
                }),
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-edit',
                    onclick: function (e) {
                        e.preventDefault();
                        app.modal.show(new EncaminhamentoModal({encaminhamento: encaminhamento}));
                    }
                })
            ])
        ])
    ];
}

export default class EncaminhamentoListPage extends Page {
    init() {
        super.init();

        this.loading = true;
        this.moreResults = false;
        this.encaminhamentos = [];
        this.refresh();
    }

    view() {
        let loading;

        if (this.loading) {
            loading = LoadingIndicator.component();
        } else if (this.moreResults) {
            loading = Button.component({
                children: app.translator.trans('iete-amigosdacura.admin.encaminhamento.load_more_button'),
                className: 'Button',
                onclick: this.loadMore.bind(this)
            });
        }
        return <div className="ListPage">
            <div className="ListPage-header">
                <div className="container">
                    <p>{app.translator.trans('iete-amigosdacura.admin.encaminhamento.about_text')}</p>
                    {Button.component({
                        className: 'Button Button--primary',
                        icon: 'plus',
                        children: app.translator.trans('iete-amigosdacura.admin.encaminhamento.new_button'),
                        onclick: () => app.modal.show(new EncaminhamentoModal({forAll: true}))
                    })}
                </div>
            </div>
            <div className="ListPage-list">
                <div className="container">
                    <div className="ListItems">
                        <label>{app.translator.trans('iete-amigosdacura.admin.encaminhamento.list_title')}</label>
                        <ol className="List">{this.encaminhamentos.length > 0 ? this.encaminhamentos.map(EncaminhamentoItem) : app.translator.trans('iete-amigosdacura.admin.encaminhamento.empty')}</ol>
                        <div className="ListPage-loadMore">
                            {loading}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    refresh(clear = true) {
        if (clear) {
            this.loading = true;
            this.encaminhamentos = [];
        }

        return this.loadResults().then(
            results => {
                this.encaminhamentos = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    loadResults(offset) {
        const params = {};
        params.page = {
            offset: offset,
            limit: 50
        };
        params.sort = 'nome';

        return app.store.find('encaminhamentos', params);
    }

    loadMore() {
        this.loading = true;

        this.loadResults(this.encaminhamentos.length)
            .then(this.parseResults.bind(this));
    }

    /**
     *
     * @param {Encaminhamento[]} results
     * @returns {Encaminhamento[]}
     */
    parseResults(results) {
        [].push.apply(this.encaminhamentos, results);

        this.loading = false;
        this.moreResults = !!results.payload.links.next;

        m.lazyRedraw();

        return results;
    }
}
