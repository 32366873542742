import app from 'flarum/app';
import icon from 'flarum/helpers/icon';
import ItemList from 'flarum/utils/ItemList';
import Component from 'flarum/Component';
import Button from 'flarum/components/Button';
import DiscussionPacienteFieldModal from "./DiscussionPacienteFieldModal";

export default class DiscussionPacienteFieldViewer extends Component {
    init() {
        this.pacientes = app.store.all('pacientes');
        this.discussion = this.props.discussion;
    }

    view() {
        if (this.pacientes.length == 0) {
            return m('div');
        }

        this.paciente = this.discussion.pacientes()[0];
        const item = this.paciente ? this.item(this.paciente) : '';

        return m('form.Discussion-Paciente-Fields.Discussion-Paciente-Fields--viewer', {
            onsubmit(event) {
                event.preventDefault();
            },
        }, [
            Button.component({
                className: 'Button Discussion-Paciente-Fields--edit',
                children: app.translator.trans('iete-amigosdacura.forum.paciente.paciente_editar'),
                icon: 'fas fa-pen',
                onclick: () => app.modal.show(new DiscussionPacienteFieldModal({
                    discussion: this.discussion,
                }))
            }),
            m('.Discussion-Paciente-Wrapper',
                m('.Discussion-Paciente-Grid', [
                    item
                ])
            )
        ]);
    }
    item(paciente){
        return m('.Discussion-Paciente-Row', [
            m('.Discussion-Paciente-Column', [
                m('.Discussion-Paciente-Field.Form-group', [
                    m('label', app.translator.trans('iete-amigosdacura.forum.paciente.paciente_label')),
                    m('.FormControl.Discussion-Pacientes-Inline', [
                        m('span.Discussion-Paciente-Inline', {
                            value: paciente.id(),
                        }, paciente.nome())
                    ])
                ])
            ])
        ]);
    }

}
