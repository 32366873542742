import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';
import computed from 'flarum/utils/computed';
import {getPlainContent} from 'flarum/utils/string';

export default class Material extends mixin(Model, {
  title: Model.attribute('title'),
  description: Model.attribute('description'),
  youtube_id: Model.attribute('youtube_id'),
  publico: Model.attribute('publico'),
  categorias_id: Model.attribute('categorias_id'),
  view_count: Model.attribute('view_count'),
  categoria: Model.hasOne('categoria'),
  quiz: Model.hasMany('quiz'),
}) {
}
