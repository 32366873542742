import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';
import computed from 'flarum/utils/computed';
import { getPlainContent } from 'flarum/utils/string';

export default class Paciente extends mixin(Model, {
    nome: Model.attribute('nome'),
    nascimento: Model.attribute('nascimento'),
    sus: Model.attribute('sus'),
    tel: Model.attribute('tel'),
    mae: Model.attribute('mae'),
    endereco: Model.attribute('endereco'),
    suspeita: Model.attribute('suspeita'),
    status: Model.attribute('status'),
    inicio_sintomas: Model.attribute('inicio_sintomas'),
}) {}
