import {extend} from 'flarum/extend';
import app from 'flarum/app';
import PacientesListPage from "./components/PacientesListPage";
import IndexPage from "flarum/components/IndexPage";
import LinkButton from 'flarum/components/LinkButton';


export default function () {
    app.routes.paciente_directory = {path: '/pacientes', component: PacientesListPage.component()};

    extend(IndexPage.prototype, 'navItems', function (items) {
        items.add('pacienteDirectory',
            LinkButton.component({
                href: app.route('paciente_directory', this.stickyParams()),
                children: app.translator.trans('iete-amigosdacura.forum.nav.pacientes'),
                icon: 'far fa-user'
            }),
            84
        );
    });
}
