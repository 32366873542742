import {extend} from 'flarum/extend';
import DiscussionComposer from 'flarum/components/DiscussionComposer';
import DiscussionPacienteField from "./components/DiscussionPacienteField";

export default function () {
    DiscussionComposer.prototype.pacientes = [];

    extend(DiscussionComposer.prototype, 'headerItems', function (items) {
        items.add('discussion-pacientes', DiscussionPacienteField.component({
            onchange: paciente => {
                this.paciente = paciente
            }
        }));
    });

    extend(DiscussionComposer.prototype, 'data', function (data) {
        data.relationships = data.relationships || {};
        if(this.paciente)
            data.relationships.pacientes = this.paciente;
    });
}
