import app from 'flarum/app';
import Page from 'flarum/components/Page';
import Button from 'flarum/components/Button';
import LoadingIndicator from 'flarum/components/LoadingIndicator';

import PacientesModal from './PacientesModal';
import Paciente from "../../../common/model/Paciente";
import DiscussionHistoricoEncaminhamentoModal from '../../../forum/encaminhamento/components/DiscussionHistoricoEncaminhamentoModal';

function PacienteItem(paciente) {
    const historicoAction = function(paciente) {
        app.modal.show(new DiscussionHistoricoEncaminhamentoModal({paciente: paciente}));
    };
    const reprintCard = ((paciente.status() == null || paciente.status() == "A confirmar" || paciente.status() == "Imprimir cartão" ) ? null : "REEMPRIMIR CARTÃO");
    return [
        m('li', {"data-id": paciente.id()}, [
            m('div', {className: 'Item-info'}, [
                m('span', {className: 'Item-name'}, [
                    paciente.nome(),
                ]),
                m('span', {className: 'Item-name'}, [
                    paciente.status(),
                ]),
                m('span', {className: 'Item-name'}, [
                    reprintCard
                ]),
                // Button.component({
                //     children: app.translator.trans('iete-amigosdacura.forum.discussion_controls.historico_button'),
                //     icon: 'fas fa-history',
                //     onclick: historicoAction.bind(paciente)
                // }),
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-trash',
                    onclick: function (e) {
                        e.preventDefault();
                        if (confirm(app.translator.trans('iete-amigosdacura.forum.modal_paciente.delete_confirmation'))) {
                            paciente.delete().then(() => m.route(app.route('paciente_directory')));
                        }
                    }
                }),
                Button.component({
                    className: 'Button Button--link',
                    icon: 'fas fa-edit',
                    onclick: function (e) {
                        e.preventDefault();
                        app.modal.show(new PacientesModal({paciente: paciente}));
                    }
                })
            ])
        ])
    ];
}

export default class PacientesListPage extends Page {
    init() {
        super.init();

        this.loading = true;
        this.moreResults = false;
        this.pacientes = [];
        this.newPacientes = [];
        this.access_token = null;
        this.refresh();
        this.query = m.prop(null);
    }

    view() {
        let loading;

        if (this.loading) {
            loading = LoadingIndicator.component();
        } else if (this.moreResults) {
            loading = Button.component({
                children: app.translator.trans('iete-amigosdacura.forum.paciente.load_more_button'),
                className: 'Button',
                onclick: this.loadMore.bind(this)
            });
        }
        return <div className="ListPage">
            <div className="ListPage-header">
                <div className="container">
                    <p>{app.translator.trans('iete-amigosdacura.forum.paciente.about_text')}</p>
                    {Button.component({
                        className: 'Button Button--primary',
                        icon: 'plus',
                        children: app.translator.trans('iete-amigosdacura.forum.paciente.new_button'),
                        onclick: () => app.modal.show(new PacientesModal({forAll: true}))
                    })}
                </div>
            </div>
            <div className="ListPage-list">
                <div className="container">
                    <form className="Form" onsubmit={this.filterPatient.bind(this)}>
                        <div className="Form-group">
                            <input type="text" placeholder="Pesquise pelo nome do paciente..." className="FormControl" bidi={this.query}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="ListPage-list">
                <div className="container">
                    <div className="ListItems">
                        <label>{app.translator.trans('iete-amigosdacura.forum.paciente.list_title')}</label>
                        <ol className="List">{this.newPacientes.length > 0 ? this.newPacientes.map(PacienteItem) : app.translator.trans('iete-amigosdacura.forum.paciente.empty')}</ol>
                        <div className="ListPage-loadMore">
                            {loading}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="ListPage-list">
                <div className="container">
                    <div className="ListItems">
                        <label>{app.translator.trans('iete-amigosdacura.forum.paciente.old_list_title')}</label>
                        <ol className="List">{this.pacientes.length > 0 ? this.pacientes.map(PacienteItem) : app.translator.trans('iete-amigosdacura.forum.paciente.empty')}</ol>
                        <div className="ListPage-loadMore">
                            {loading}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    filterPatient(e) {
        e.preventDefault();
        this.refresh()
    }

    refresh(clear = true) {
        if (clear) {
            this.loading = true;
            this.pacientes = [];
        }

        return this.loadResults().then(
            results => {
                this.pacientes = [];
                this.newPacientes = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    loadResults(offset) {
        const params = {};
        if(this.query){
            params.nome = this.query()
        }
        params.page = {
            offset: offset,
            limit: 50
        };
        params.q = m.route.param('q')
        params.sort = 'nome';
        // params.sort = 'title';
        return app.store.find('pacientes', params);
    }

    loadMore() {
        this.loading = true;

        this.loadResults(this.pacientes.length)
            .then(this.parseResults.bind(this));
    }

    /**
     *
     * @param {Paciente[]} results
     * @returns {Paciente[]}
     */
    parseResults(results) {
        let newPacientesTmp = results.filter(p => (p.status() == null || p.status() == 'A confirmar' || p.status() == 'Imprimir cartão'));
        let oldPacientesTmp = results.filter(p => (p.status() != null && ( p.status() != 'A confirmar' || p.status() != 'Imprimir cartão')));
        [].push.apply(this.newPacientes, newPacientesTmp);
        [].push.apply(this.pacientes, oldPacientesTmp);

        this.loading = false;
        this.moreResults = !!results.payload.links.next;

        m.lazyRedraw();

        return results;
    }
}
