import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class Quiz extends mixin(Model, {
  name: Model.attribute('name'),
  active: Model.attribute('active'),
  material_id: Model.attribute('material_id'),
  questions: Model.hasMany('questions'),
}) {
}
