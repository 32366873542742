import {extend} from 'flarum/extend';
import app from 'flarum/app';
import MateriaisPage from "../home/components/MateriaisPage";
import Search from "flarum/components/Search";
import MaterialSearchSource from "./components/MaterialSearchSource";
import MaterialPage from "../home/components/MaterialPage";

export default function () {
    app.routes.materiais = {
        path: '/materiais',
        component: MateriaisPage.component(),
    };
    app.routes.material = {
        path: '/materiais/:id',
        component: MaterialPage.component(),
    };

    extend(Search.prototype, 'sourceItems', function (items) {
        if (app.forum.attribute('canViewUserList')) items.add('materiais', new MaterialSearchSource());
    });
}
