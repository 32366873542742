import PagePage from './PagePage';
import Button from 'flarum/components/Button';
import Alert from "flarum/components/Alert";


function QuestionOption(option, question, respondido) {
  return m('div', {className: "Question"}, [
    m('label', {
      className: respondido && option.is_correct() ? "resposta-certa" : (option.answers().length > 0 && option.answers()[0].answer_id() == option.id() ? "resposta-errada" : "")
    }, [
      m('input[type=radio]', {
        checked: option.answers().length > 0 ? option.answers()[0].answer_id() == option.id() : question.value == option.id(),
        disabled: respondido,
        name: option.question_id(), onchange: ($event) => {
          question.value = option.id()
        }
      }),
      option.answer()
    ])
  ])
}

function QuizQuestion(question, i, respondido) {
  return [
    m('div', {className: "Question"}, [
      m('h4', (i + 1) + ". " + question.question()),
      question.options().map((v) => QuestionOption(v, question, respondido))
    ])
  ];
}

function Quiz(quiz, sendAnswers) {
  return [
    m('div', {className: "Quiz"}, [
      m('h3', quiz.name()),
      (!quiz.valid?" Acerto: "+(100*quiz.acerto/quiz.questions().length)+"%":""),
      quiz.questions().map((v1, i1) => QuizQuestion(v1, i1, !quiz.valid)),
      Button.component({
        className: 'Button Button--primary hasIcon',
        children: "Salvar",
        icon: 'fas fa-check',
        disabled: !quiz.valid,
        onclick: () => {
          sendAnswers(quiz)
        },
      })
    ])

  ];
}


function MaterialItem(material, quiz, sendAnswers) {
  return [
    m('div', {className: "MaterialListItem", "data-id": material.id()}, [
      m('div', {className: 'MaterialListItem-content'}, [
        m('div', {className: 'MaterialListItem-main'}, [
          m('h3', {className: 'MaterialListItem-title'}, [
            material.title(),
          ]),
          m('div', {className: 'MaterialListItem-icon MaterialIcon'}, [
            <iframe className="MaterialPlayer" width="100%" height="100%"
                    src={"//www.youtube.com/embed/" + material.youtube_id() + "?enablejsapi=1&rel=0&origin=" + window.location.origin}
                    frameborder="0"></iframe>
          ]),
          m('p', {className: 'MaterialListItem-description'}, [
            material.description(),
          ])
        ])
      ])
    ]),
    m('div',
      quiz ? Quiz(quiz, sendAnswers) : ""
    )
  ];
}

export default class MaterialPage extends PagePage {
  init() {
    super.init();

    this.title = "Materiais";
    this.loading = true;
    this.moreResults = false;
    this.material = null;
    this.quiz = null;
    this.refresh();

    this.error = false;
    this.msg = "";

    app.history.push('materiais', app.translator.trans('iete-amigosdacura.forum.header.back_to_materiais_tooltip'));
  }

  content() {
    return (
      <div className="MaterialPage">
        {this.material != null ? new MaterialItem(this.material, this.quiz, this.sendAnswers.bind(this)) : app.translator.trans('iete-amigosdacura.forum.material.empty')}
      </div>
    );
  }

  refresh() {
    this.id = m.route.param('id') || 0;
    app.store.find('materiais', this.id)
      .then((material) => {
        this.material = material;
        if (this.material.quiz().length > 0) {
          if (!this.quiz) // Inicializa apenas uma vez
            app.store.find('quiz', this.material.quiz()[0].id()).then((quiz) => {
              this.quiz = quiz;
              this.quiz.questions().forEach((v) => {
                v.value = false;
              });

              // Apenas possivel responder uma vez
              this.quiz.valid = this.quiz.questions()[0].options().reduce((prev, att) => {
                return prev + att.answers().length
              }, 0) == 0;

              // Se respondido
              if(!this.quiz.valid){

                //Contagem de acertos
                this.quiz.acerto = this.quiz.questions().reduce((prev, att)=>{
                  return prev + att.options().reduce((prev2, att2) => {
                    return prev2 + ((att2.answers().length > 0 && att2.is_correct())?1:0)
                  }, 0)
                },0);
              }
              m.redraw();
            });
        }
        m.redraw();
      });
  }

  sendAnswers(quiz) {
    const questions = quiz.questions();
    for (var i = 0; i < questions.length; i++) {
      if (!questions[i].value) {
        this.error = new Alert({
          type: 'error',
          children: app.translator.trans('iete-amigosdacura.forum.material.quiz_empty')
        });
        app.alerts.show(this.error);
        return;
      }
    }
    const answers = app.store.createRecord('quiz_answer');
    answers.save({
      questions: questions.reduce((prev, val) => {
        prev.push({value: val.value, id: val.id()});
        return prev
      }, []),
    }, response => {
      this.loading = false;
      this.onerror(response);
    }).then(() => {
      window.location.reload();
    }).catch(() => {
      this.loading = false;
      m.redraw();
    });

  }
}
